

.paaf_grid{
    &--avatar {
        width: 32px;
        height: 32px;
        border-radius: 100%;
    }

    &--avatar_1_5 {
        width: 48px;
        height: 48px;
        border-radius: 100%;
    }
}

.more-dot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}


