@import "../../styles/core/core-variables";
.grid-list-container {
    min-width: 250px;
    text-align: right;
}

.search_grid {
    &--avatar {
        width: 32px;
        height: 32px;
        border-radius: 100%;
    }
}


.search-table-level {
    border-spacing: 0;

    thead {
        background-color: $blue-shade-1;
        font-size: $sm-font;
    }

    tbody {
        font-size: $sm-font;

        tr {
            &.dark {
                background-color: $grey-shade-1;
            }
        }
    }

    td,
    th {
        padding: $padding-2 $padding-4;
        text-align: left;
    }
}

.grid-no-message {
    height: 300px;
}

.affiliation-checkbox {
    padding: 0.9rem 0.25rem 0.5rem 1.8rem !important;
}