@import "../../styles/core/core-variables";

.icon.async-search-icon {
    position: absolute;
    left: 8px;
    top: 5px;
    color: $primary-color;
}

.applied-filter-icon-container {
  // height: 18px;
  // padding-right: $padding-3;
}

.icon.async-clear-icon {
  position: absolute;
  right: 10px;
  top: 9px;
  cursor: pointer;

  &.appliedFilter {
    top: 33px;
    &.label-inactive {
      top: 31px;
    }
  }
  // color: $primary-color;
}

i.circular.icon.applied-filter-icon {
  background-color: $primary-color !important;
}

/* The container */
.customCheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  // margin-bottom: 12px;
  cursor: pointer;
  font-size: 0.75rem;
  color: $grey-shade-3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Create a custom checkbox */
.multiSelectDropdonCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: $white-color;
  border: 2px solid $primary-color;

  &.check {
    background-color: $primary-color;

    &::before {
      position: absolute;
      left: -6px;
      top: 50%;
      height: 50%;
      width: 2px;
      background-color: $white-color;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    
    &::after {
      position: absolute;
      left: -6px;
      bottom: 0;
      height: 2px;
      width: 100%;
      background-color: $white-color;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
  }
}

.smartOptionsPopup {
  &.ui.popup {
    // min-width: 200px;
    padding: 0.5rem;
    max-height: 300px;
    overflow: auto;

    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

.options-container {
  .ui.button {
    border: 1px solid $primary-color;
    color: $primary-color;
    border-radius: 100%;
    min-width: 35px;
    height: 35px;
    padding: 0.25rem;
    font-size: 0.75rem;

    &:hover, &:active, &:focus {
      background-color: transparent;
    }
  }
}

.ui.input.async-select-input {
  height: 30px;
  background: transparent;

  input {
    border: 0;

    ::placeholder {
      color: $black-shade-1;
      opacity: 1; /* Firefox */
    }
  }

  &.active-placeholder {
    ::placeholder {
      color: $secondary-color;
    }
  }

  

  .search.icon {
    color: $primary-color;
    opacity: 1;
  }
}
