.background {
  &-primary-color {
    background-color: $primary-color;
  }

  &-transparent {
    background-color: transparent;
  }

  &-secondary-color {
    background-color: $secondary-color;
  }
  
  &-grey-shade-1 {
    background-color: $grey-shade-1;
  }

  &-grey-shade-3 {
    background-color: $grey-shade-3;
  }
  
  &-grey-shade-7 {
    background-color: $grey-shade-7;
  }

  &-white-color {
    background-color: $white-color;
  }

  &-dark-blue {
    background-color: $dark-blue;
  }

  &-blue-shade-2 {
    background-color: $blue-shade-2;
  }
}
