.border {
  &-none {
    border: none;
  }

  &-1 {
    border: 1px solid;
  }
  
  &-top {
    border-top: 1px solid;
  }
  &-bottom {
    border-bottom: 1px solid;
  }
  &-left {
    border-left: 1px solid;
  }
  &-right {
    border-right: 1px solid;
  }

  //Color
  &-primary-color {
    border-color: $primary-color;
  }

  &-secondary-color {
    border-color: $secondary-color;
  }

  &-grey-shade-2 {
    border-color: $grey-shade-2;
  }
  
  &-grey-shade-8 {
    border-color: $grey-shade-8;
  }
  &-grey-shade-10 {
    border-color: $grey-shade-10;
  }

  &-grey-shade-11 {
    border-color: $grey-shade-11;
  }
  
  //Radius
  &-radius-50 {
    border-radius: 50%;
  }
  &-radius-100 {
    border-radius: 100%;
  }

  &-radius-1 {
    border-radius: 1rem;
  }
  &-radius-0 {
    border-radius: 0;
  }
}