.dnd-card {
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: .25rem;
    cursor: move;

    .reactCustomCheckbox  {
        cursor: inherit;
    }


    &.disable {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &.move {
        opacity: 0;
    }
}