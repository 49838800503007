 @import"../../../styles/core/core-variables";

.banner {
    &-container {
        height: 250px;
    
        .title-1 {
            line-height: 2.5rem;
            background-color: $grey-shade-5;
        }
    
        .title-2 {
            font-size: 3rem;
            line-height: 4.25rem;
            background-color: $grey-shade-5;
        }
    
        .title-3 {
            line-height: 2.25rem;
            font-size: 1.75rem;
            background-color: $grey-shade-5;
        }
    }

    &-logo {
        top: 0;
    }

    &-waves-logo {
        top: 0;
        right: 0;
    }
}

.tutorial-start-button {
    right: 1rem;
    bottom: 1rem;
    z-index: 300;
}

.manual-start-button{
    right: 1rem;
    top: 1rem;
    z-index: 300;
}