@import "./core/index";

html, body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 16px;
  font-family: $primary-font;
  color: $secondary-color;

  @media only screen and (max-width: 1919px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 1920px) {
    font-size: 16px;
  }
}

#root {
  height: 100%;
  width: 100%;
  position: relative;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
}

.min-content-height {
  min-height: calc(100% - 145px);
}

.height {
  // pixel
  &-min-80 {
      min-height: 80px;
  }
}

.width {
  // percentage
  &-min-50 {
      min-width: 50px;
  }
}

a {
  &.no-hover {
    &:hover {
      color: inherit;
    }
  }
}

.paaf-user-table-container {
  height: calc(100vh - 190px);
}

.react-datepicker__navigation-icon::before {
  border-color: $primary-color;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__day--in-range {
  background-color: $primary-color;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: transparent;
  border: 1px solid $primary-color;
  color: $primary-color;
}


.datepicker-clear {
  right: 17px;

}

.react-datepicker__close-icon::after {
  background-color: $white-color;
  color: $secondary-color;
  font-size: $x2lg-font;

}

.form-date-picker .datepicker {
  &::placeholder {
    font-size: $sm-font;
  }
}

.ui.progress {
  .bar {
    background-color: $primary-color;
  }
}