@import "../../styles/core/core-variables";

.present-filter {

    &-content {
        display: flex;
        &:hover {
            background-color: $grey-shade-1;
            .title {
                color: $secondary-color;
                text-decoration: underline;
            }
        }
    }

    &-dropdown {
        &.ui.popup {
            padding: 0;
            .content {
                min-width: 200px;
                max-height: 300px;
                overflow: auto;
            }
        }
    }

    &-item {
        padding: 1rem;
        border-left: 2px solid transparent;
        max-width: 300px;

        &:hover {
            background-color: $white-color;
            color: $primary-color;
            border-color:$primary-color;
        }
    }
}