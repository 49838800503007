@import "../../../styles/core/core-variables";

.ui.modal.affilation-modal {

  &.mini {
    width: auto;
    min-width: 320px;
  }

  .content {
    padding-bottom: $padding-0;
    height: 300px;
    overflow: auto;
  }
}