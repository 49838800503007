@import "../../styles/core/core-variables";

.reactCustomCheckbox {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 0.9rem 0.25rem 0.9rem 2.5rem;
  font-size: $md-font;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 100px;

  &.disbaled {
    opacity: 0.7;
  }

  // max-width: calc(100% - 40px);
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  

  .checkmark {
    position: absolute;
    left: 0.9rem;
    top: 1.1rem;
    height: 16px;
    width: 16px;
    background-color: white;
    border: 2px solid #197a56;

    &.partialChecked {
      padding: 1px;


      .partialCheckContainer {
        width: 100%;
        height: 100%;
        background-color: $primary-color;
      }
    }
  }

  input:checked ~ .checkmark {
    background-color: #197a56;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 3px;
    top: -2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.left-align {
    padding-left: 1.5rem;
    .checkmark  {
      left: 0;
  }
  }
}
