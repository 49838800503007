@import "../../styles/core/core-variables";
.taxanomy-dropdown-container {
    height: 38px;
    background-color: $white-color;
    border-radius: 20px;
    border: 1px solid $grey-shade-8;
    cursor: pointer;
    padding: 1px 8px;
    color: $primary-color;
    &:hover{
        border-color: $green-shade-1;
    }

    svg {
        fill: $primary-color;
    }

    .placeholder {
        display: flex;
        align-items: center;
        height: 100%;
        color: $grey-shade-8;
        margin-left: 2px;
        margin-right: 2px;
        padding: 2px;
        box-sizing: border-box;
        font-size: $sm-font;
        font-family: $primary-font;
    }


    &.taxanomy-open {
    background-color: $primary-color;
    color: $white-color;

        .placeholder, .single-value-tree {
            color: $white-color;
        }

        svg {
            fill: $white-color;
        }
    }
}

.applied-filter-icon-container {
    height: 18px;
} 

i.circular.icon.applied-filter-icon-taxanomy {
    background-color: $primary-color !important;
  }

.ui.popup.taxanomy-dropdown, .taxanomy-dropdown {
    padding: 0;
    border-radius: 8px;
    .header {
        padding: 1rem 1rem 0 1rem;
    }

    .options-scroll-container {
        max-height: 250px;
        min-width: 200px;

        overflow: auto;

        &.show-background {
            &.childrens {
                background-color: rgba(196, 196, 196, 0.3);
       
                &.odd {
                   background-color: $white-color;
                }
               }
        }
    }

    .ui.input.taxanomy-filter-input {
        width: 100%;
        height: 32px;
        border: 1px solid $primary-color;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        .ui.basic.label {
            width: 2rem;
            padding: 0 0.5rem;
            background-color: transparent;
            border: none;
        }

        input {
            height: 100%;
            width: calc(100% - 6rem);
            border: none;
            padding: 0 !important;
            font-size: $md-font;
            font-family: $primary-font;
            background-color: transparent;

            &::placeholder {
                color: $primary-color;  
            }
        }
        i {
            color: $primary-color;
            opacity: 1;
            font-size: 1rem;
            margin: 0;

            &.close {
                color: $default-color;
            }

        }
    }


    .taxonamy-option {
        color: $grey-shade-9;
        align-items: center;
        .taxanomy-checkbox {
            padding: 0.9rem;
            padding-right: 0.25rem;
            font-size: $md-font;
            input:checked~label:before {
                background-color: $primary-color;
            }
            input:checked~label:after {
                color: $white-color;
                font-size: 1rem;
            }

        }

        // &.active {
        //  background-color: rgba(196, 196, 196, 0.3);
        // }

        &:hover, &.active {
            background-color: $primary-color;
            color: $white-color;

            .icon.taxanomy-arrow-icon {
                color: $primary-color;
            }
        }

        &.inverted {
         background-color: rgba(196, 196, 196, 0.3);
            
            // &:hover {
            //     background-color: $white-color;
            // }
        }

        .icon.taxanomy-arrow-icon {
            color: $grey-shade-9;
            width: 40px;
            height: 40px;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }


    .single-value-label {
        padding: 0.9rem 0.25rem 0.9rem 0.25rem;
        font-size: $md-font;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        max-width: calc(100% - 40px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
}


.taxanomy-dropdown {
    &.no-dropdown {
        height: 100%;

        .ui.input.taxanomy-filter-input {
            height: 40px;
        }

        .taxanomy-option-wrapper {
            height: calc(100% - 40px);
        }
    }
}

