@import "../../styles/core/core-variables";

.customInput {
  height: 38px;
  border: 1px solid $grey-shade-8;
  padding-left: 2.5rem;
  color: $grey-shade-8;
  font-size: $md-font;

  &.no-icon {
    padding-left: 1rem;
  }

  &:hover {
    border-color: $primary-color;
  }

  &:focus-visible {
    border-color: $primary-color;
    outline: none;
  }

  &::placeholder {
    font-size: $sm-font;
    color: $grey-shade-8;
  }

  ~.input-icon {
    position: absolute;
    left: 10px;
    top: 9px;
  }

  &.rounded-corner {
    border-radius: 20px;
  }

}

.form-date-picker {
  .datepicker {
    height: 38px;
    border-radius: 20px;
    border: 1px solid $grey-shade-8;
    padding-left: 1rem;
    color: $grey-shade-8;
    font-size: $md-font;
    width: 100%;

    &:hover {
      border-color: $green-shade-1;
    }

    &:focus-visible {
      border-color: $primary-color;
      outline: none;
    }

    &::placeholder {
      font-size: $md-font;
      color: $grey-shade-8;
    }
  }

  .dropdown-icon {
    position: absolute;
    right: 10px;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }
}