/*
* Font Size
* Our Root font size is 16px;
*/


$spacing: 0.25rem;

$xs-font: 0.5rem;
$sm-font: 0.75rem;
$md-font: 0.88rem;
$lg-font: 1rem;
$x1lg-font: 1.25rem;
$x2lg-font: 1.5rem;
$x3lg-font: 1.75rem;
$x4lg-font: 2rem;

// Font Family
$primary-font: 'Henderson BCG Sans';

// Color Variables
$primary-color: #197A56;
$green-shade-1: #32c77f;
$white-color: #FFFFFF;
$secondary-color: #323232;
$default-color: #000000;


$yellow-shade-1:#FFFBE8;

$dark-blue: #0A477D;
$blue-shade-1:#E0F1FF;
$blue-shade-2: #cce2ff;



$grey-shade-1: #F2F2F2;
$grey-shade-2: #535353;
$grey-shade-3: #111111;
$grey-shade-4: #e2e2e2;
$grey-shade-5: #00000090;
$grey-shade-6: #E0F1FF1A;
$grey-shade-7: #F2F2F296;
$grey-shade-8: #7F7F7F;
$grey-shade-9: #575757;
$grey-shade-10:#B1B1B1;
$grey-shade-11:#D4D4D4;

$black-shade-1: #111111;

$danger-color: #D82216;
$red-shade-1:#D82216;
$red-shade-2:#A1150C;

// Padding Variables
$padding-0: 0rem;
$padding-1: 0.25rem;
$padding-2: 0.5rem;
$padding-3: 0.75rem;
$padding-4: 1rem;
$padding-5: 1.25rem;
$padding-6: 1.5rem;
$padding-7: 1.75rem;
$padding-8: 2rem;
$padding-9: 2.25rem;
$padding-10: 2.5rem;
$padding-11: 2.75rem;
$padding-12: 3rem;

// Margin Variables
$margin-0: 0rem;
$margin-1: 0.25rem;
$margin-2: 0.5rem;
$margin-3: 0.75rem;
$margin-4: 1rem;
$margin-5: 1.25rem;
$margin-6: 1.5rem;
$margin-7: 1.75rem;
$margin-8: 2rem;

// Screen Resolution Variables
$mobile-min: 320px;
$mobile-max: 767px;

$tab-min: 768px;
$tab-max: 1100px;

$dekstop: 1101px;
$large-monitor: 1920px;