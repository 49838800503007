@import "../../styles/core/_core-variables.scss";

header {
    background: $grey-shade-1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: sticky;
    top: 0;
    z-index: 700;

    .logo {
        padding: $padding-5;
        padding-left: $padding-0;
        // cursor: pointer;
    }
}

.menu-link-container {
    .menu-link {
        border-top: 3px solid transparent;
        color: $default-color;
    }

    &:hover {
        background-color: $primary-color;
        .menu-link {
        color: $white-color;
        }
        .vector-icon{
            color: $white-color;
        }
    }

    &.active {
        .menu-link {
            color: $primary-color;
            border-color: $primary-color;
        }

        &:hover {
            background-color: $primary-color;
            .menu-link {
            color: $white-color;
            }
        }
    }
}

.itemWithPopup {
    margin-top: 2.5px;
    &.ui.dropdown {
        display: flex;
        color: $default-color;
        .custom-svg-icon {
            margin-left: $margin-2;
        }

        .menu {
            top: 6rem;
            border-radius: 0;
        }

        &:hover {
            color: $white-color;
            background-color: $primary-color;
        }
    }
}

.avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
}

.vector-icon{
    display:inline-block;
}