@import "../../../styles/core/core-variables";

.ui.modal.role-modal {

    &.mini {
      width: auto;
      min-width: 320px;
    }
  
    .content {
        padding: 0;
        min-height: 200px;
       
        &.tree-selection {
            padding: $padding-4 !important;
            height: 300px;
            overflow: auto;
        }
    }
  }