@import "../../styles/index.scss";
.pagination-dropdown {
    &.ui.dropdown {
        display: flex;
        color: $primary-color;

        .item {
            font-size: $sm-font;
            padding: $padding-2 !important;
        }
    }
}