@import "../../../styles/index.scss";

.affiliation-card {
    padding: $padding-4;
    margin-bottom: $margin-4;

    &__details {
        width: 25%;
        padding-right: 1rem;

        &--avatar {
            width: 100px;
            height: 100px;
            border-radius: 100%;
        }

        &--full-detail {
            background-color: $yellow-shade-1;
            height: 100%;
        }

        &--tab {
            width: 48%;
            padding: 0.5rem 0;
        }
    }

    &__action {
        width: 80px;
    }

    &__table {
        width: calc(75% - 80px);

        &.overflow {
            height: 100px;
            overflow-y: auto;
        }

        table {
            width: 100%;
            border-spacing: 0;
            table-layout: fixed;

            thead {
                background-color: $blue-shade-1;
                font-size: $sm-font;
                position: sticky;
                top: 0;
            }

            tbody {
                font-size: $sm-font;

                tr {
                    &.dark {
                        background-color: $grey-shade-1;
                    }
                }
            }

            td,
            th {
                padding: $padding-2 $padding-4;
                text-align: left;
            }
        }

    }

    
}