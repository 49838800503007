@import "../../styles/core/core-variables";

.customTextArea {
  min-height: 38px;
  padding: $padding-3 $padding-4;
  color: $grey-shade-8;
  font-size: $md-font;
  border: 1px solid $grey-shade-8;

  &::placeholder {
    padding-top: 0.1rem;
    font-size: $sm-font;
    color: $grey-shade-8;
  }

  &:focus-visible {
    border-color: $primary-color;
    outline: none;
  }

  &.rounded-corner {
    border-radius: 20px;
  }
}