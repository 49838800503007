@import "../../styles/core/core-variables";
.react-table {
    position: relative;
    height: 100%;
    width: 100%;

    &--header {
        border: 1px solid $primary-color;
        background-color: $primary-color;
        height: 48px;
        padding-right: 10px;

        &.search-active {
            height: 85px;
        }
    }

    &--header-cell {
        color: $white-color;
        padding: $padding-4;
        font-size: $lg-font;
        border-right: 1px solid rgba(224, 241, 255, 0.1);
        .actions {
            position: relative;
            padding-right: $padding-12;
        }

        .header-filter-input {
                height: 30px;
                border: 1px solid $grey-shade-8;
                padding-left: 0.5rem;
                color: $grey-shade-8;
                font-size: $md-font;
                width: 100%;
              
                &:hover {
                  border-color: $primary-color;
                }
              
                &:focus-visible {
                  border-color: $primary-color;
                  outline: none;
                }
              
                &::placeholder {
                  font-size: $sm-font;
                  color: $grey-shade-8;
                }
        }
    }

    &--scroll {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        padding-right: 10px;

        &.pagination{
            bottom: 35px;
        }
    }


    &--body {
        height: calc(100% - 48px);
        overflow-y: scroll;
        border: 1px solid $grey-shade-10;
        border-top: none;
        overflow-x: hidden;

        &.pagination-active {
            height: calc(100% - 97px);
        }

        &.search-active {
            height: calc(100% - 85px);

            &.pagination-active {
                height: calc(100% - 134px);
            }
        }
    }

    #left-container, #right-container{
        .react-table--body-overflow{
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.45);
        }
    }

    &--body-overflow {
        .body-row:nth-child(odd) {
            background-color: #F7F7F7;
        }

        .body-row:nth-child(even) {
            background-color: #D9D9D9;
        }
    }

    .body-row {
        .body-cell {
            padding: 0 $padding-4;
            height: 50px;
            border-right: 1px solid transparent;
            display: flex;
            align-items: center;
        }

        &.no-static-height {
            .body-cell {
                align-items: flex-start;
                padding: $padding-2 $padding-4;
                height: auto;
            }
        }
    }

    
    .header-actions {
        position: absolute;
        right: 0;
        top: -2px;

        &--button {
            cursor: pointer;
            padding: $padding-1;

            &:hover {
                background-color: #00000033;
            }
        }
    }

    &--no-data,  &--loading {
        width: 100%;
        height: 100%;
        min-height: 100px;
        border: 1px solid $grey-shade-10;
        border-top: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

