@import "../../../styles/core/core-variables";

.ui.modal.tutorial-modal{

    &.mini {
        width: auto;
        min-width: 350px;
      }

    border: 4px solid $primary-color;
}