@import "../../../styles/core/core-variables";

.ui.modal.save-filter-modal {

    .content {
        overflow: auto;

        .ui.grid>.row {
            padding-top: $padding-2;
            padding-bottom: $padding-2;
        }
    }

}