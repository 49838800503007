@import "../../../styles/core/core-variables";

.tutorial-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
.tutorial-content {
   display: flex;
   flex-direction: column;
   min-width: 400px;
}

.tutorial-pointing {
    border: 1px solid $primary-color;
}


.ui.popup.tutorial-popover {
    border-radius: 2rem;
    border: 1px solid $primary-color;
}
