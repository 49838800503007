@import "../../styles/core/core-variables";
.custom-svg-icon {
  border: 1px solid transparent;
  &.large {
      width: 2rem;
      height: 2rem;
  }

  &.huge {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.small {
    width: 1rem;
    height: 1rem;
  }

  &.mini {
    width: 0.5rem;
    height: 0.5rem;
  }

  &.circular {
      border-radius: 100%;
  }
}


i.icon {
  &.primary-color {
    color: $primary-color;
  }

  &.danger-color {
    color: $danger-color;
  }
}